.scrollable-list-panel {
  .scrollable-list-panel-list {
    margin-left: -1rem;
    margin-right: -1rem;
    .scrollable-list-panel-timeline {
      padding: 0;
    }
    .scope-of-work-item {
      margin-left: 0;
      padding: 0.5rem 0 0.5rem 1rem;
      margin-bottom: 0;
      .comment-item-left {
        flex-grow: 1;
        max-width: calc(100% - 20px - 1rem);
      }
      .comment-item-right {
        margin-right: 1rem;
        .ellipsis {
          display: none;
        }
      }
      &:hover {
        background-color: #e8ebeb;
        .comment-item-right {
          .ellipsis {
            display: inline-block;
          }
        }
      }
    }
  }
}

.scope-of-work-item {
  display: flex;
  margin-left: 1rem;
  margin-bottom: 2rem;
  position: relative;
}

.enquiry-panel {
  flex-grow: 1;
  margin-left: 0.5rem;
  width: 100%;

  .ui.compact.button {
    height: initial;
  }
  .light-panel.ui.segment {
    > header {
      height: 3.5rem;
      .action-bar {
        height: 100%;
        align-items: center;
      }
    }
    & > div {
      padding-bottom: 1rem;
    }
  }
  .enquiry-description {
    height: 50vh;
    display: flex;
    flex-direction: column;
    .light-panel-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .ui.form {
        height: 100%;
        .field {
          height: 100%;
        }
      }
    }
  }
}

.enquiry-panel:first-child {
  margin-left: 0;
}

.cin.side-panel.actions-side-panel {
  min-width: fit-content;
  .filters-title {
    display: flex;
    align-items: center;
    > .clear-label {
      margin-left: 0.5rem;
      font-size: 16px;
    }
  }

  .ui.icon.button.close-panel {
    z-index: 10;
  }
  .actions {
    margin-left: 1rem;

    a {
      @include OpenSans16BoldText;
      color: $main-colour;
    }
    a:hover {
      color: $main-colour-hover;
      cursor: pointer;
    }

    & > div {
      padding-bottom: 1rem;
    }
  }
}
.enquiry-quote-right {
  .ui.button {
    height: 32px !important;
  }
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.enquiry-current-quote-panel {
  display: flex;
  flex-direction: row;
  & > .side-bar {
    width: 25%;
    flex-shrink: 0;

    .cin.loadable:first-child .ui.segment.expandable-content-panel {
      margin-top: 0;
    }

    pre {
      @include OpenSans14RegularText;
      white-space: pre-line;
    }
  }
  & > .scope-of-work {
    flex-basis: 75%;
    flex-grow: 1;
    margin-left: 1rem;

    .table-header {
      background-color: $table-header-bg-color;
    }
    .table-footer {
      display: flex;
      background-color: $table-header-bg-color;
      padding: 0.5rem 1rem;
      font-weight: bold;
    }

    .quote-row {
      display: flex;
      padding: 0.5rem 1rem;
    }

    .ui.segment {
      margin-top: 0;
      padding-bottom: 1rem;
    }

    .productName {
      width: 50%;
    }
    .productNumber {
      width: 10%;
    }
    .itemNumber {
      width: 5%;
    }

    @media screen and (max-width: 1450px) {
      .productName {
        width: 25%;
        word-wrap: break-word;
      }
      .productNumber {
        width: 15%;
        word-wrap: break-word;
      }
      .itemNumber {
        width: 8%;
      }
    }

    .quote-item {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border-color: $table-item-border-color;
      border-style: solid;
      border-radius: 7px;
      border-width: 1px;
      display: flex;
      padding: 0.5rem 1rem;
      cursor: pointer;

      .actions {
        align-self: center;
      }
    }
  }
}

.scope-of-work-view-footer {
  margin: -1rem -2rem 0 -2rem;
  width: auto;
  section {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 0.78571429em 0.78571429em;
      font-weight: bold;
    }
    .productName {
      width: 62.5%;
    }
    .productNumber {
      width: 7.5%;
    }
  }
}

.table-panel.ui.segment .ui.table.scope-of-work-view-table {
  td:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  .sow-icons {
    display: flex;
    align-content: space-between;
    justify-content: center;
    align-items: center;
  }
}

.enquiry-images {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  .enquiry-images-button {
    &:first-child {
      .ui.button {
        float: left;
      }
    }
    &:last-child {
      .ui.button {
        float: right;
      }
    }
    flex-grow: 1;
    flex-shrink: 0;
    .ui.button {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
  .enquiry-images-img {
    flex-grow: 0;
    flex-shrink: 1;
    img.ui.image {
      max-width: 100%;
      max-height: 20rem;
    }
  }
}

.line-panel.ui.segment.enquiry-card {
  $line-colours: "blue" $main-colour, "green" $dark-green, "orange" $orange,
    "red" $red, "grey" $box-grey, "faded-blue" $faded-blue,
    "faded-green" $faded-green, "residential-green" $residential-green,
    "commercial-blue" $commercial-blue "contract-red" $red;
  $border-width: 3px;
  @each $name, $colour in $line-colours {
    &.highlight-#{$name} {
      &:before {
        content: none;
      }
      border-left: solid #{$border-width} $colour;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
    }
  }
  margin-bottom: 1rem;
}

.enquiry-visits {
  .actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
  iframe {
    width: 100%;
    height: 60vh;
    margin-top: 1em;
  }
}

.enquiry-visit-message {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.enquiries-table.ui.table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;

  $border-width: 3px;
  $line-colours: "residential" $residential-green, "commercial" $commercial-blue,
    "contract-red" $red;
  $tint-colours: "green" $green-tint, "orange" $orange-tint, "red" $red-tint,
    "grey" $grey-tint, "light-blue" $pale-blue;

  tbody {
    tr {
      @each $name, $colour in $line-colours {
        &.highlight-#{$name} {
          td:first-child {
            border-left: solid #{$border-width} $colour !important;
          }
        }
      }

      @each $name, $colour in $tint-colours {
        &.tint-#{$name} {
          background-color: $colour;
        }
      }
    }
  }
}

.edit-visit-row {
  .ui.input input {
    flex: 1 1 auto;
    width: 100% !important;
  }

  .time-select {
    width: 5rem;
  }
}

.ui.segment.light-panel.quote-notes-panel {
  header {
    padding-left: 2rem;
  }
  .light-panel-content {
    padding-left: 3rem;
  }
  pre {
    margin: 0;
    white-space: pre-line;
  }
}

.equipment-form {
  width: 80%;
  > header .ui.segment {
    @include OpenSans16BoldText;
    height: 3.5rem;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    > button {
      margin-left: auto;
    }
  }
  .equipment-panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .individual-panel {
      flex: 0 0 32%;
      margin-bottom: 1em;
      margin-top: 0;
      > header {
        @include OpenSans14BoldText;
      }
      &.general-panel {
        > header {
          @include OpenSans15BoldText;
        }
        flex-basis: 49%;
      }
      &.notes-panel {
        > header {
          @include OpenSans15BoldText;
        }
        flex-basis: 50%;
        &.simple {
          flex-basis: 49%;
        }
      }
      .read-only {
        white-space: pre-line;
        min-height: 6em;
      }
    }
  }
}
