.left-input-icon .ui.image {
  position: absolute;
  left: 0.9rem;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1.1rem;
}

.ui.form .field label {
  @include OpenSans12RegularText;
}

.ui.labeled.input > .label {
  padding-top: 9px !important;
}

.ui.input {
  height: 32px;
}

.ui.form .field .ui.dropdown {
  min-height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  & > .search {
    padding-top: 6px;
  }
  & > .icon.dropdown {
    padding-top: 7px;
  }
  & > .text {
    margin-top: 3px;
    line-height: 1.5;
  }
}

.search-filter-display {
  display: flex;

  > .filter-display {
    @include OpenSans14BoldText;
    margin-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .filter-display-item {
      background-color: $main-colour;
      border-radius: 0.25rem;
      color: $white;
      padding: 0 0.5rem;
      height: 1.5rem;
      font-size: 12px;
      margin: 0 0.25rem;
      max-width: 10rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .clear-label {
      margin-left: 0.5rem;
      font-size: 16px;
      line-height: 1.75rem;
    }
  }
}

.ui.form .inline-radio-group > .field {
  display: flex;
  flex-wrap: wrap;
  & > label {
    width: 100% !important;
    margin-bottom: 0.3rem !important;
    @include OpenSans12RegularText;
  }

  .ui.checkbox > label {
    @include OpenSans14RegularText;
  }
}

.ui.radio.checkbox input:checked ~ label:after,
.ui.radio.checkbox input:focus:checked ~ label:after {
  background-color: $main-colour;
}

.ui.form .form-picker-items {
  display: flex;
  flex-wrap: wrap;
  .radio {
    flex-basis: calc((100% - 6rem) / 4);
  }
  .checkbox {
    margin-right: 1rem;
    flex-basis: calc((100% - 4rem) / 4);

    label {
      padding-left: 1.5rem;
      @include OpenSans14RegularText;
    }
  }
}

.ui.form .picker-loader {
  position: relative;
  padding: 1rem 0;
}

.ui.form .field:not(.inline) > .ui.checkbox.aligned-checkbox {
  margin-top: 2em;
}

.colour-picker.field {
  .inputs {
    display: flex;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15);
    align-items: center;

    .picker {
      height: 32px;
      flex: 1 1 auto;

      .clickable-container {
        padding: 0.3em;
        border-radius: 1px;
        display: inline-block;
        cursor: pointer;
        width: 100%;
        height: 100%;

        .colour-container {
          border-radius: 2px;
          width: 100%;
          height: 100%;
        }
      }

      .colour-popup {
        position: absolute;
        z-index: 2;

        .colour-popup-container {
          position: fixed;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
        }
      }
    }

    .clear {
      flex: 0 0 auto;
      padding: 0.5em 0.5em 0.5em 0.25em;
      cursor: pointer;
      height: 25px;
    }
  }
}
