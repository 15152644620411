.ui.modal {
  border-top: 4px solid $main-colour;
  position: static;
  & > .header {
    font-size: 20px !important;
    color: $black;
  }
  & > .actions {
    & .ui.button.negative {
      float: left;
      background-color: transparent;
      color: $black;
      font-size: 12px;
      font-weight: normal;
    }
    & .ui.button > .icon {
      display: none;
    }
  }
}

.no-padding-modal {
  .content {
    padding: 0 !important;
    .dimmable {
      min-height: 4rem;
    }
  }
}

.ui.modal.select-address-modal {
  .actions {
    & > .ui.button.positive {
      background-color: $main-colour;
    }
  }
  .content {
    padding-top: 0.5rem;
  }
  .cin.tile-grid {
    & > .cell {
      padding-left: 0;
      padding-right: 14px;
      &:nth-child(2n) {
        padding-right: 7px;
      }

      & > .new-address {
        border-radius: 0;
        min-height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $main-colour;
        cursor: pointer;
      }
    }
    padding-top: 0;
    padding-bottom: 1rem;
  }
  header {
    @include Calibri14BoldText;
    padding-bottom: 0.5rem;
  }

  .address-tile.ui.segment {
    min-height: 0;
    & > header {
      @include OpenSans14BoldText;
    }
  }
}

.sow-item-modal {
  hr {
    background-color: $divider-grey;
    border: none;
    height: 1px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .quote-item-product-filters {
    :first-child {
      margin-right: 1rem !important;
    }
    > * {
      width: calc(50% - 0.75rem);
      .ui.search.selection.dropdown {
        width: 100% !important;
      }
    }
  }

  .job-modifiers-label {
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .ui.form {
    .fields {
      align-items: center;
      &.job-modifiers {
        margin-bottom: 0;
        .ui.checkbox {
          margin: auto;
        }
      }
    }

    .item-details {
      :last-child {
        flex-grow: 1;
      }
    }

    .product-options {
      display: flex;

      > div {
        flex-grow: 1;

        .field {
          width: 100%;
          flex-wrap: wrap;

          .ui.input {
            flex-grow: 1;
          }
          input {
            width: 100%;
          }

          .dropdown {
            flex-grow: 1;
          }

          .form-error {
            margin-top: 0.5rem;
          }
        }
      }
    }

    .field label {
      @include OpenSans16RegularText;
      display: inline-block;
    }

    .field.inline {
      display: inline-flex;

      label {
        margin-bottom: auto;
        margin-top: auto;
      }
    }

    .ui.input,
    input,
    .ui.selection.dropdown {
      display: block;
      height: 32px;
    }

    .inline .ui.input {
      display: inline-block;
    }

    .ui.input.search {
      margin-bottom: 1rem;
    }

    .ui.labeled.input {
      .ui.label {
        height: calc(2rem + 12%);
      }
      input {
        display: inline-block;
        width: 70%;
      }
    }
  }

  .ui.label {
    background-color: $picker-selected-bg-color;
    color: $white;
  }
}
