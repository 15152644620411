.fullscreen-page {
  min-height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  &.fixed {
    max-height: calc(100% - 64px);
    overflow: hidden;
  }
  .page-content-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
