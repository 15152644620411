@use "sass:math";

.dashboard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;

  > .graph-widget.ui.segment {
    $third: math.div(100%, 3);
    flex-basis: calc(#{$third} - 2rem);
    width: 0; // fix resize issue
    min-width: 400px;
    height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    .cin.loadable {
      height: 100%;
    }
    header {
      display: flex;
      .title {
        margin-top: 4px;
        margin-bottom: 0;
      }
    }
    footer {
      flex-grow: 1;
      display: flex;
    }

    @media screen and (max-width: 700px) {
      flex-basis: calc(100% - 2rem);
    }
  }

  > .department-widget.ui.segment,
  .bar-widget.ui.segment {
    flex-basis: 100%;
    height: 300px;
    > header {
      margin-bottom: 1rem;
    }
    > footer .cin.loadable {
      width: 100%;
    }
  }

  .legend-text {
    color: $black;
  }

  .no-data {
    align-self: center;
    display: block;
    text-align: center;
    width: 100%;
  }

  .recharts-default-legend {
    display: flex;
    flex-direction: column;
    > .recharts-legend-item {
      margin-bottom: 0.5rem;
    }
  }

  .departments-filter {
    .ui.form {
      display: flex;
      flex-wrap: wrap;
      .field {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        &:first-child {
          margin-left: 0.5rem;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.dashboard-filters-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 1rem 1rem 1rem;
  .dashboard-filters {
    display: inline-block;
    .ui.form {
      display: flex;
      > .field:not(:last-child) {
        margin-right: 1rem;
      }
      .field label {
        @include OpenSans15BoldText;
      }
    }
  }
}
