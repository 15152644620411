.cin.side-panel.filter-side-panel {
  .filters-title {
    display: flex;
    align-items: center;
    > .clear-label {
      margin-left: 0.5rem;
      font-size: 16px;
    }
  }

  .field {
    margin-bottom: 1rem !important;
  }
  .ui.checkbox {
    margin-bottom: 0 !important;
  }

  .ui.icon.button.close-panel {
    z-index: 10;
  }
  .ui.grid {
    margin: 0 !important;
    > .row {
      padding: 0 !important;
      > .column {
        padding: 0 0.5rem !important;
      }
    }
    > .column {
      padding: 0 0.5rem !important;
    }
  }
}

.enquiry-card.ui.segment {
  border-radius: 0;
  padding: 0;
  > .enquiry-background {
    background-position: right center;
    background-repeat: no-repeat;
    padding-bottom: 0.5rem;
    > .title {
      display: flex;
      padding: 1rem 1rem 0 1rem;
      margin-bottom: 0.5rem;
      @include OpenSans10RegularText;
      .enquiry-title {
        @include OpenSans16SemiBoldText;
        flex-grow: 1;
        margin: 0;
        padding-bottom: 0.5rem;
      }
      & :not(.enquiry-title) {
        flex-grow: 0;
        .ui.image {
          margin-left: 0.5rem;
        }
      }
    }

    .enquiry-body {
      padding: 0 1rem;
      > p {
        margin: 0;
        padding-left: 20px;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 11px;
        > .ui.image {
          position: absolute;
          left: 0;
        }
        > span {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-grow: 0;
        }
      }
      & :last-child {
        margin-bottom: 0px;
      }
    }
  }
  > .enquiry-footer {
    background-color: $pale-blue;
    padding: 0 1rem 0 1rem;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include OpenSans12RegularText;
    .enquiry-date {
      @include OpenSans10RegularText;
      flex-grow: 0;
    }
  }

  $tint-colours: "green" $green-tint, "orange" $orange-tint, "red" $red-tint,
    "blue" $pale-blue, "grey" $grey-tint;
  @each $name, $colour in $tint-colours {
    &.highlight-#{$name} .enquiry-footer {
      background-color: $colour;
    }
  }
}

.contract-card.ui.segment {
  border-radius: 0;
  padding: 0;
  > .contract-background {
    background-position: right center;
    background-repeat: no-repeat;
    padding-bottom: 0.5rem;
    > .title {
      display: flex;
      padding: 1rem 1rem 0 1rem;
      margin-bottom: 0.5rem;
      @include OpenSans10RegularText;
      .contract-title {
        @include OpenSans16SemiBoldText;
        flex-grow: 1;
        margin: 0;
        padding-bottom: 0.5rem;
      }
      & :not(.contract-title) {
        flex-grow: 0;
        .ui.image {
          margin-left: 0.5rem;
        }
      }
    }

    .contract-body {
      padding: 0 1rem;
      > p {
        margin: 0;
        padding-left: 20px;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 11px;
        > .ui.image {
          position: absolute;
          left: 0;
        }
        > span {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-grow: 0;
        }
      }
      & :last-child {
        margin-bottom: 0px;
      }
    }
  }
  > .contract-footer {
    background-color: $pale-blue;
    padding: 0 1rem 0 1rem;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include OpenSans12RegularText;
    .contract-date {
      @include OpenSans10RegularText;
      flex-grow: 0;
    }
  }

  $tint-colours: "orange" $orange-tint, "red" $red-tint, "purple" $purple-tint;
  @each $name, $colour in $tint-colours {
    &.highlight-#{$name} .contract-footer {
      background-color: $colour;
    }
  }
}
