.ui.table {
  border-radius: 0;
  border: 0;

  i.icon.ellipsis.horizontal:before {
    color: $blue;
  }
}

i.icon.ellipsis {
  font-size: 1.25rem;
}

.table-panel.ui.segment {
  padding-left: 0;
  padding-right: 0;
  border-right-width: 0;
  border-left-width: 0;
  & > header {
    padding: 1rem 2rem 1rem 2rem;
    display: flex;
    & > * {
      flex-grow: 1;
    }
    .title {
      @include OpenSans23BoldText;
    }
    .page-size {
      flex-grow: 0;
    }
    .search-filter-display {
      align-items: center;
    }

    .header-filter-right {
      display: flex;
      justify-content: flex-end;
    }
  }
  & > footer {
    padding: 1rem 2rem 0 2rem;
  }

  .ui.table {
    @include OpenSans14RegularText;
    margin-top: 0;
    margin-bottom: 0;

    tbody tr:last-child td,
    thead tr:first-child th {
      border-bottom: 2px solid $border-colour;
    }
    thead {
      @include OpenSans13RegularText;
    }
    td {
      box-shadow: none !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid $border-colour;
    }
    th {
      background-color: white;
    }
    tbody tr:hover {
      background-color: $hover-colour !important;
    }

    tbody tr td:first-child,
    thead tr th:first-child {
      padding-left: 2rem;
    }
    tbody tr td:last-child,
    thead tr th:last-child {
      padding-right: 2rem;
    }
  }
  .field .text {
    font-size: $font-size !important;
    overflow: visible;
  }
}

.table-pagination {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  & > label {
    position: absolute;
    left: 0;
  }

  & > div.page-size {
    position: absolute;
    right: 0;
  }
  .ui.pagination.menu {
    .active.item {
      background-color: $main-colour;
      &:focus {
        outline-color: asRGBA($black, 0.25);
      }
    }
    .icon.item:focus {
      outline-color: asRGBA($black, 0.25);
    }
  }
}

.page-size {
  cursor: pointer;
  border: solid 1px $border-grey;
  background-color: $field-background-color;
  color: $box-grey;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

.date-filters {
  display: flex;
  justify-content: flex-end;
  .time-select .ui.input {
    width: 150px !important;
  }
  .filter-field {
    display: flex;
    .ui.dropdown {
      height: 37px;
    }
    & > .title-label {
      align-self: center;
      padding: 0 0.5rem 0 1rem;
    }
  }
}

.table.ui .icon.ellipsis {
  color: $main-colour;
}

.icon.button.squareish {
  border-radius: 3px;
}
