.ui.compact.dropdown.cin.action-menu.contract-action-menu {
  img.ui.image {
    display: inline !important;
    margin-right: 0.5rem;
  }

  .caret.down.icon {
    margin-left: 0.5rem;
  }

  .action-menu-item {
    display: flex;
  }
}
