.icon-text-column {
  img {
    margin-right: 0.25rem;
  }
}

.jobs-table.ui.table {
  $border-width: 3px;

  border-collapse: separate;
  border-spacing: 0 0.25rem;

  $line-colours: "blue" $main-colour, "green" $dark-green, "orange" $orange,
    "red" $red, "grey" $box-grey, "residential" $residential-green,
    "commercial" $commercial-blue, "fencing" $red, "landscaping" $orange,
    "landscapingandfencing" $purple;
  $tint-colours: "green" $green-tint, "orange" $orange-tint, "red" $red-tint,
    "blue" $paler-blue, "grey" $grey-tint, "light-blue" $pale-blue;

  th {
    padding: 0.92857143em 0.78571429em !important;

    &:first-child {
      padding-left: 2rem !important;
      border-left: solid #{$border-width} transparent;
    }
  }
  tbody {
    tr {
      @each $name, $colour in $line-colours {
        &.highlight-#{$name} {
          td:first-child {
            border-left: solid #{$border-width} $colour !important;
          }
        }
      }

      @each $name, $colour in $tint-colours {
        &.tint-#{$name} {
          background-color: $colour;
        }
      }

      .ui.disabled.checkbox {
        opacity: 40%;
      }
    }
  }
}

.job-side-panel,
.ui.segment.job-side-panel {
  flex-basis: 15%;
  margin-right: 0.5rem;
  min-height: 20rem;
  height: auto;
  p {
    @include Lato16BoldText;
  }
  .job-link:first-child {
    padding-top: 0;
  }
  .job-link {
    padding-top: 1.5rem;
  }
  a {
    text-decoration: underline;
  }
  .job-value {
    padding-top: 4rem;
  }
}

.job-tab-content {
  flex-grow: 1;
  display: flex;
  max-width: 100%;
  .ui.segment {
    margin-top: 0;
  }

  > * {
    width: 100%;
  }

  & > .table-panel {
    height: fit-content;
  }

  td.item-scheduled div.ui.checkbox {
    label::after {
      content: "\e800";
      transform: none;
      opacity: 1;
      color: $light-grey;
    }
  }

  & > div {
    flex-grow: 1;
    margin-left: 0.5rem !important;
    .light-panel.ui.segment {
      > header {
        height: 3.5rem;
        .action-bar {
          height: 100%;
          align-items: center;
        }
      }
      & > div {
        padding-bottom: 1rem;
      }
    }
    .enquiry-description {
      height: 50vh;
      display: flex;
      flex-direction: column;
      .light-panel-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .ui.form {
          height: 100%;
          .field {
            height: 100%;
          }
        }
      }
    }
  }

  .job-sheet-panel {
    max-height: 100%;
    overflow-y: auto;
    display: grid;
    padding-left: 0;
    padding-right: 0;
    grid-template-rows: 60fr 40fr;
    grid-template-columns: 50fr 50fr;
    row-gap: 1rem;

    header {
      @include OpenSans16BoldText;
    }

    .scope-of-work {
      grid-row: 1;
      grid-column: 1 / 3;
      header {
        display: flex;
        justify-content: space-between;
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .ui.checkbox.job-item-on-different-sheet {
        label::after {
          content: "\e800";
          transform: none;
          opacity: 1;
          color: $light-grey;
        }
      }
    }
    .team {
      margin-left: 1rem;
      grid-row: 2;
      grid-column: 1;
      ul {
        list-style: none;
        padding-left: 1rem;
      }

      li:before {
        content: url("../assets/icons/user.svg");
        display: inline-block;
        vertical-align: middle;

        margin-right: 1rem;
      }
    }
    .notes {
      margin-right: 1rem;
      grid-row: 2;
      grid-column: 2;
      header {
        display: flex;
        justify-content: space-between;
        .ui.compact.icon.button {
          font-size: 12px;
          padding-top: 0;
          padding-bottom: 0;
          height: 26px;
        }
      }
      pre {
        @include OpenSans14RegularText;
        white-space: pre-line;
        margin: 0;
      }
    }
  }

  .job-sheet-visits {
    header .ui.segment {
      @include OpenSans16BoldText;
      height: 3.5rem;
    }

    max-height: calc(100% - 51px - 0.5rem);

    .ui.segment:last-child {
      margin-bottom: 14px;
    }

    .visit {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      &.selected-visit {
        background-color: $picker-selected-bg-color;
        color: $white;
      }
      .ui.compact.icon.button {
        font-size: 12px;
        padding-top: 0;
        padding-bottom: 0;
        height: 26px;
      }
      .visit-actions {
        display: inline-flex;
        margin-left: 0.5rem;
      }
    }

    & > .cin.loadable {
      overflow-y: auto;
      max-height: 100%;
    }

    width: 25%;
    flex-grow: 0;
    margin-right: 1rem;
  }
}

.job-details-wrapper {
  height: calc(100vh - #{$navHeight});
}

.job-details-content-wrapper {
  height: calc(100% - #{$page-content-header-height});
  max-height: calc(100% - #{$page-content-header-height});

  & > .cin.loadable {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .tabbed-container {
      flex-grow: 1;
      max-height: calc(100% - 91px);

      .tab-menu {
        height: 49px;
      }

      .tabbed-content {
        height: calc(100% - 49px - 1.5rem);
      }
    }
  }
}

.repeat-job-form {
  > button.ui.button {
    margin-top: 1rem;
  }
  .summary-message {
    @include OpenSans16SemiBoldText;
    margin-top: 1rem;
  }
  .fields:first-child {
    .field label {
      visibility: visible;
    }
  }
  .fields {
    .field label {
      visibility: collapse;
    }
  }
  .visits {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .fields {
      .field {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
        .labeled.input {
          display: inline-flex;
          input {
            flex: auto 1 2;
            min-width: 0;
          }
          .label {
            flex: auto 0 1;
          }
        }
      }
      .field:first-child {
        padding-left: 0;
      }
      margin: 0 !important;

      .ui.button.remove-button {
        background: none;
        color: $black;
        align-self: flex-end;
        padding: 1.5em 0.5em 0.5em 0.5em;
        i {
          height: 15px;
        }
      }
      .form-error {
        max-width: fit-content;
      }
    }
  }
}

.ui.form.cancel-job-form .cancel-types {
  margin-bottom: 1rem;
  > div {
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > .ui.checkbox {
      margin: 0;
      margin-bottom: 0.5em;
      > label {
        @include OpenSans14RegularText;
      }
    }
  }
}

.job-fulfilment-of-works-panel {
  display: flex;
  flex-direction: column;
  .ui.segment {
    padding-left: 0;
    padding-right: 0;
    .header-bar {
      display: flex;
      padding: 1rem 2rem 1rem 2rem;
    }
    .ui.table {
      thead {
        font-size: 13px;
        th {
          background-color: white;
          &:nth-child(1) {
            padding-left: 2rem;
          }
        }
      }
      tbody {
        td {
          &:nth-child(1) {
            padding-left: 2rem;
          }
          &:nth-last-child(1) {
            padding-right: 1rem;
          }
          .ui.image {
            margin-left: 2rem;
          }
        }
      }
    }
  }
  .light-panel {
    margin-top: 1rem !important;
    .ui.compact.button.cancel-button {
      background: none;
      color: $black;
    }
    .notes-display {
      white-space: pre-wrap;
    }
    .ui.form {
      textarea {
        height: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.completed-radio-group {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  .field {
    display: flex;
    flex-wrap: wrap;
    label {
      width: 100%;
    }
    .ui.checkbox {
      margin-right: 2.5em !important;
    }
  }
}
