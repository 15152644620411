.customer-tile.ui.segment {
  border-radius: 0;
  border: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  height: 100%;
  background-size: 4rem;

  > header {
    @include OpenSans16SemiBoldText;
    margin-bottom: 8px;
  }

  .customer-tile-line {
    position: relative;
    padding-left: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 6px 0;

    &:last-child {
      margin-bottom: 0 !important;
    }

    > img {
      position: absolute;
      top: 2px;
      left: 0px;
    }
  }
}

.card-grid-fill.cin.tile-grid {
  margin-top: 0.5rem;

  @include BuildTileFillMediaQuery(1460px, 9999px, 5);

  @include BuildTileFillMediaQuery(1194px, 1459px, 4);

  @include BuildTileFillMediaQuery(922px, 1193px, 3);

  @include BuildTileFillMediaQuery(656px, 921px, 2);
}

.card-grid-breakpoints-fill.cin.tile-grid {
  margin-top: 0.5rem;

  @include BuildTileBreakpointsFillMediaQuery(1000px, 9999px, 4);

  @include BuildTileBreakpointsFillMediaQuery(750px, 999px, 3);

  @include BuildTileBreakpointsFillMediaQuery(500px, 749px, 2);
}

.line-panel.ui.segment.enquiry-card.customer-enquiry-card {
  margin: 0;
}

.customer-header-button.ui.button {
  height: 32px;
  padding: 0 0.5rem;
  margin-right: 1rem;
}

.ui.form.customer-filters {
  .field:first-child {
    margin-left: 0;
  }
  .field {
    margin-left: 1rem;
    > div {
      display: inline-flex;
      vertical-align: middle;
    }
  }
  .field .ui.dropdown > .text {
    font-weight: normal;
  }
}

.light-panel.overview.ui.segment {
  & > header {
    padding: 8px 1rem;
    .ui.button {
      min-width: 125px;
    }
  }
  .cin.action-bar-item {
    align-self: center;
  }
}

.ui.form {
  .primaryAddressCheckbox {
    height: 100%;
    .field:not(.inline) {
      height: 100%;
      display: flex;
      align-items: center;
      > .ui.checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }
}

.overview-record.ui.segment.content-panel {
  $line-colours: "blue" $main-colour, "green" $dark-green, "orange" $orange,
    "red" $red, "light-blue" $light-blue;
  $border-width: 3px;
  @each $name, $colour in $line-colours {
    &.highlight-#{$name} {
      &:before {
        content: none;
      }
      border-left: solid #{$border-width} $colour;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
    }
  }
}

.customers-table.ui.table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;

  $border-width: 3px;
  $line-colours: "residential" $residential-green, "commercial" $commercial-blue,
    "contract" $red;

  tbody {
    tr {
      @each $name, $colour in $line-colours {
        &.highlight-#{$name} {
          td:first-child {
            border-left: solid #{$border-width} $colour !important;
          }
        }
      }

      &.archived {
        td {
          cursor: default !important;
        }
      }
    }
  }
}
