.table-panel.ui.segment .departments-table.ui.table {
  height: 1px;
  tbody tr td:first-child {
    padding: 0.5rem 1rem 0.5rem 1rem;

    div {
      width: 100%;
      height: 100%;
      border-radius: 25px;
    }
  }

  @supports (-moz-appearance: none) {
    tbody tr td:first-child {
      height: 100%;
    }
  }
}
