.scrollable-list-panel-list {
  .scrollable-list-panel-timeline {
    $offset: -20px;

    .comment-item-bullet {
      &::before {
        content: "";
        display: block;
        width: 1px;
        height: calc(100% + 2rem);
        background-color: $black;
        position: absolute;
        top: 6px;
        left: $offset;
        border-radius: 50%;
      }
      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background-color: $black;
        position: absolute;
        top: 6px;
        left: calc(#{$offset} - 4px);
        border-radius: 50%;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }

    .comment-item {
      display: flex;
      margin-left: 3rem;
      margin-bottom: 2rem;
      position: relative;
      .comment-item-left {
        flex-grow: 1;
        max-width: calc(100% - 20px - 1rem);
      }
      .comment-item-right {
        margin-right: 1rem;
      }
      header {
        @include OpenSans14RegularText;
        margin-right: 1rem;
        overflow-wrap: break-word;
      }
      footer {
        @include OpenSans10RegularText;
        color: $black;
        margin-top: 0.25rem;

        .bullet-point {
          margin: 0 0.5rem;
        }
      }
    }
  }
}
