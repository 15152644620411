.signature-image {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20rem;
  margin-bottom: 1rem;
  img.ui.image {
    max-width: 100%;
    max-height: 100%;
  }
}

.signature-remove-button {
  position: absolute;
  top: 0;
  right: 0;
}
