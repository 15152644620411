.public-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .logo-wrapper {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }

  .public-content-link {
    margin-bottom: 1rem;
    float: right;
  }

  .ui.error.message {
    width: 100%;
  }
}

.login.split-page {
  > .left-content {
    background-image: url("~assets/login.jpg");
    background-position: center center;
    background-color: $background-color;
    background-size: cover;
    @media screen and (max-width: calc(#{$desktop} - 1px)) {
      display: none;
    }
  }
  > .right-content {
    background-color: $black;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-header {
    @include Lato20BoldText;
    margin-bottom: 1rem;
  }

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
    }
  }

  p {
    @include Lato14RegularText;
  }

  .ui.form .field label {
    color: $white;
  }
}
