@import "./variables.scss";
@import "~@redriver/cinnamon/src/styles/cinnamon.scss";
@import "~simplebar-core/dist/simplebar.css";
@import "./functions.scss";
@import "./mixins.scss";
@import "./global.scss";
@import "./navigation.scss";
@import "./containers.scss";
@import "./table.scss";
@import "./forms.scss";
@import "./buttons.scss";
@import "./modals.scss";
@import "./swimlanes.scss";
@import "./addresses.scss";
@import "./comments.scss";
@import "./controls.scss";
@import "./sidepanels.scss";
@import "./pages.scss";
@import "./dashboard.scss";

// page specific styles
@import "./customers.scss";
@import "./enquiries.scss";
@import "./invoices.scss";
@import "./jobs.scss";
@import "./login.scss";
@import "./scheduling.scss";
@import "./quotes.scss";
@import "./users.scss";
@import "./settings.scss";
@import "./departments.scss";
@import "./contracts.scss";
@import "./display.scss";
