body,
#root {
  background-color: $background-color;
  height: 100%;
  width: 100%;
}

.environment-banner {
  background-color: $red;
  border: 1px solid white;
  color: white;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 36px;
  line-height: 30px;
  opacity: 0.6;
  position: fixed;
  left: -130px;
  text-align: center;
  bottom: 50px;
  transform: rotate(45deg);
  width: 400px;
  z-index: 10000;
  pointer-events: none;
}

.system-page-content {
  width: 400px;
  margin: 100px auto;
}

.load-more-button {
  width: 100%;

  > .ui.button {
    display: block;
    margin: 0 auto;

    &.disabled,
    &:disabled {
      opacity: 1 !important;
    }
  }
}

a {
  @include Lato13RegularText;
  &:hover {
    color: $dark-green;
  }
}
a.dark {
  @include Lato13RegularText;
  color: $black;
  &:hover {
    color: $black;
  }
}
a.basic {
  color: $black;
  &:hover {
    color: $black;
  }
}

a.main {
  color: $main-colour;
}
a.main:hover {
  color: $main-colour-hover;
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.tabbed-container .react-swipeable-view-container > div {
  width: auto !important;
}

.ui.breadcrumb {
  & > a {
    @include OpenSans16BoldText;
    color: $black;
    &:hover {
      color: $black;
    }
  }
}

.ui.segment {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0 $box-shadow-black;
}

.cin.tree-view {
  .cin.tree-node {
    & > .ui.button {
      height: auto;
    }
  }
}

.cin.side-panel {
  min-width: 500px;
}

.pointer {
  cursor: pointer;
}

.action-link,
.action-link:hover {
  color: $main-colour;
  cursor: pointer;
  display: inline-block;
}

.clickable {
  cursor: pointer;
}

.inline {
  display: inline;
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
